import { ReactElement, Fragment } from 'react';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Row } from '@utmb/design-system/box/Box';
// Types
import { PartnerView, SlicePartnersView } from '@utmb/types/index';
import { Slice } from '@utmb/types/slice-handler';
// Styles
import styles from './partners.module.scss';

const cx = classNames.bind(styles);

export const Partners = (props: Slice<SlicePartnersView>): ReactElement | null => {
    const { id, partners } = props;

    if (!partners || !partners.length) {
        return null;
    }

    const partnersFiltered = partners.filter((partner) => partner.picture);

    if (!partnersFiltered.length) {
        return null;
    }

    const partnersGridClassesCSS = cx('utmb_container_v2_mob_pad', styles.partners_grid);
    const partnersGridContainerCSS = cx('flex-wrap', styles.partners_grid_container);
    const partnersItemCSS = cx(styles.item, {
        one_element: partnersFiltered.length === 1,
        two_elements: partnersFiltered.length === 2,
        three_elements: partnersFiltered.length === 3,
        four_elements: partnersFiltered.length === 4,
        more_elements: partnersFiltered.length >= 5,
    });

    return (
        <Row id={id} className={styles.partners_grid_wrapper}>
            <Row className={partnersGridClassesCSS}>
                <Row className={partnersGridContainerCSS}>
                    {partnersFiltered.map((partner: PartnerView, index: number) => {
                        const image = (
                            <Image
                                layout="intrinsic"
                                objectFit="contain"
                                height={partner.picture.height}
                                width={partner.picture.width}
                                image={partner.picture}
                                maxWidth={400}
                            />
                        );

                        let partnerItemCSS = partnersItemCSS;

                        if (partner.picture.width > partner.picture.height) {
                            const ratio = partner.picture.width / partner.picture.height;

                            partnerItemCSS = cx(partnersItemCSS, { larger: ratio > 5 });
                        }

                        return (
                            <Fragment key={index}>
                                <div className={partnerItemCSS}>{partner.link ? <Link href={partner.link}>{image}</Link> : image}</div>
                            </Fragment>
                        );
                    })}
                </Row>
            </Row>

            <div className={styles.partners_before}>
                <div className={styles.partners_before_c}></div>
            </div>
            <div className={styles.partners_after}>
                <div className={styles.partners_after_c}></div>
            </div>
        </Row>
    );
};
